import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import FormReservation from '../components/Slices/FormReservation';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';


class Reserve extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({
        lang: getLang().language,
      });
    }
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }    
    

  render() {


    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" style={{ marginTop: 100 }}>
          <Container>
            <Row>
              <Col className="text-center title">
                <h1 className="">
                  {APP_LANG === 'EN' ? TXTEN.RESERVE.TITLE1 : TXT.RESERVE.TITLE1}
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <FormReservation />
        </div>
      </Layout>
    );
  }

}

export default Reserve;
