import React from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Card, CardTitle, CardText, FormFeedback, FormText } from 'reactstrap';
import { Link } from 'gatsby';
import { getLang} from '../../services/lang';
import { validateName, validateEmail, validatePhone, validateCountry, validateCity, validateEmpty} from '../../services/validations';
import * as TXT from '../../res/strings';
import * as TXTEN from '../../res/strings_en';
import * as emailjs from 'emailjs-com';

const API_PATH = 'quinta-lili-Backend/api/contact/index.php';

class FormReservation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
      success: false,
      formSent: false,
      name: '',
      email: '',
      phone: '',
      country: 'Mexico',
      city: '',
      age: '',
      checkin: '',
      checkout: '',
      roomtype: '',
      num_rooms: '',
      num_adults: '',
      children12: '',
      children6: '',
      breakfast: '',
      interests: '',
      how_findus: '',
      message: '',
      validate: {
        nameState: '',
        emailState: '',
        phoneState: '',
        ageState: '',
        checkinState: '',
        checkoutState: '',
        roomtypeState: '',
        num_roomsState: '',
        num_adultsState: '',
        messageState: '',
      }
    }

    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleAge = this.handleAge.bind(this);
    
    this.handleCheckin = this.handleCheckin.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleRoomtype = this.handleRoomtype.bind(this);
    this.handleNum_Rooms = this.handleNum_Rooms.bind(this);
    this.handleNum_Adults = this.handleNum_Adults.bind(this);
    this.handleChildren12 = this.handleChildren12.bind(this);
    this.handleChildren6 = this.handleChildren6.bind(this);

    this.handleBreakfast = this.handleBreakfast.bind(this);
    this.handleInterests = this.handleInterests.bind(this);
    this.handleHow_Findus = this.handleHow_Findus.bind(this);
    this.handleMessage = this.handleMessage.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.sendMail =this.sendMail.bind(this);
    this.email1 = this.email1.bind(this);

  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({
        lang: getLang().language,
      });
    }
    this.setState({
      country:'Mexico'
    });
  }

  componentDidUpdate(){
    if (getLang().language !== this.state.lang) {
        this.setState({
          lang: getLang().language,
        });
      }
  }


  handleName(e) { this.setState({name: e.target.value}); }
  handleEmail(e) { this.setState({email: e.target.value});  }
  handlePhone(e) { this.setState({phone: e.target.value});  }
  handleCountry(e) { this.setState({country: e.target.value});  }
  handleCity(e) { this.setState({city: e.target.value});  }
  handleAge(e) { this.setState({age: e.target.value});}
  
  handleCheckin(e) { this.setState({checkin: e.target.value});  }
  handleCheckout(e) { this.setState({checkout: e.target.value});  }
  handleRoomtype(e) { this.setState({roomtype: e.target.value});  }
  handleNum_Rooms(e) { this.setState({num_rooms: e.target.value});  }
  handleNum_Adults(e) { this.setState({num_adults: e.target.value});  }
  handleChildren12(e) { this.setState({children12: e.target.value});  }
  handleChildren6(e) { this.setState({children6: e.target.value});  }

  handleBreakfast(e) { this.setState({breakfast: e.target.value});  }
  handleInterests(e) { this.setState({interests: e.target.value});  }
  handleHow_Findus(e) { this.setState({how_findus: e.target.value});  }
  handleMessage(e) { this.setState({message: e.target.value});  }

  sendMail(dataSend) {
    setTimeout( 
      () => {this.email1(dataSend) }, 1000
    );
  }
  
  email1(dataSend) {
    console.log('email1 sent');
    emailjs.send("default_service", "qltluisechavez_yahoo", dataSend, 'user_Q2WOYEgFyruYseoZ3Rp0s')
    .then(result => {
      this.setState({success: true})
    })
    .catch(error => console.log('error al enviar: ', JSON.stringify(error)));
    
  }


  handleSubmit(e) {
    e.preventDefault();
    this.setState({formSent: true});
    const dataSend = {
      from_name: 'Quinta lili Tequesquitengo',
      to_name: this.state.name,
      subject: 'Reservación desde Quintaliliteques.com',

      name: this.state.name, 
      email: this.state.email,
      phone: this.state.phone,
      country: this.state.country,
      city: this.state.city,
      age: this.state.age,

      checkin: this.state.checkin,
      checkout: this.state.checkout,
      roomtype: this.state.roomtype,
      num_rooms: this.state.num_rooms,
      num_adults: this.state.num_adults,
      children12: this.state.children12,
      children6: this.state.children6,

      breakfast: this.state.breakfast,
      interests: this.state.interests,
      how_findus: this.state.how_findus,
      message: this.state.message

    };
    console.log('datasend:', dataSend);
    const successForm = validateEmpty(dataSend);
    console.log('success FORM : ',successForm)
    if (successForm === true) {
      console.log('success datasend : ',successForm)
      console.log('datasend:', dataSend);
      console.log(`${API_PATH}`);

      this.sendMail(dataSend);
    }
    else {
      console.log('error: ', ...successForm);
      this.setState(validateEmpty(dataSend));
      this.setState({formSent: false});
    }  
    
    
  }

  render() {
    const APP_LANG = this.state.lang;
    const BUTTON_SEND = <FormGroup check row className="text-center">
    <Col>
    <Link onClick={this.handleSubmit}>
      <div className="btnAction mt-auto">
        { APP_LANG === 'EN' ? TXTEN.BUTTON.FORM_SEND : TXT.BUTTON.FORM_SEND }
      </div>
    </Link>
    </Col>
  </FormGroup>

    const BUTTON_SEND_DISABLE = <FormGroup check row className="text-center">
    <Col>
    <div>
      <div className="btnActionDisabled mt-auto">
        ENVIANDO...
      </div>
    </div>
      
    </Col>
  </FormGroup>


    if (this.state.success === false) {
        return (
            <div className="section" style={{ marginTop: 100 }}>
                <Container>
                  <Col sm="12" md={{ size: 8, offset: 2 }}>
                    <Card body>
                        <Form>
                          <FormGroup row>
                            <Label for="name" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NAME : TXT.RESERVE.FORM_LABEL_NAME}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="name" 
                                name="name" 
                                id="input_name" 
                                placeholder=""
                                value={this.state.name}
                                valid={ this.state.validate.nameState === 'has-success' }
                                invalid={ this.state.validate.nameState === 'has-danger' } 
                                onChange={ (e) => {
                                  this.setState(validateName(e));
                                  this.handleName(e);
                                } }
                              />
                              <FormText>4 chars or more</FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="email" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_EMAIL : TXT.RESERVE.FORM_LABEL_EMAIL}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="email"
                                name="email"
                                id="input_email"
                                placeholder=""
                                value={ this.state.email }
                                valid={ this.state.validate.emailState === 'has-success' }
                                invalid={ this.state.validate.emailState === 'has-danger' }
                                onChange={ (e) => {
                                  this.setState(validateEmail(e));
                                  this.handleEmail(e)
                                } }
                                />
                                <FormFeedback valid>
                                {APP_LANG === 'EN' ? TXTEN.ALERTS.EMAIL_VALID : TXT.ALERTS.EMAIL_VALID}
                                </FormFeedback>
                                <FormFeedback>
                                {APP_LANG === 'EN' ? TXTEN.ALERTS.EMAIL_INVALID : TXT.ALERTS.EMAIL_INVALID}
                                </FormFeedback>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="phone" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_PHONE : TXT.RESERVE.FORM_LABEL_PHONE}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="number" 
                                name="phone" 
                                id="input_phone" 
                                placeholder=""
                                value={this.state.phone}
                                valid={ this.state.validate.phoneState === 'has-success' }
                                invalid={ this.state.validate.phoneState === 'has-danger' }
                                onChange={ (e) => {
                                  this.setState(validatePhone(e));
                                  this.handlePhone(e);
                                } }
                              />
                              <FormText>(ex. 1234567890 or +521234567890)</FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="country" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_COUNTRY : TXT.RESERVE.FORM_LABEL_COUNTRY}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="text" 
                                name="country" 
                                id="input_country" 
                                placeholder="Mexico"
                                value={this.state.country}
                                valid={ this.state.validate.countryState === 'has-success' }
                                invalid={ this.state.validate.countryState === 'has-danger' }
                                onChange={ (e) => {
                                  this.setState(validateCountry(e));
                                  this.handleCountry(e);
                                } }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="city" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CITY : TXT.RESERVE.FORM_LABEL_CITY}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="text" 
                                name="city" 
                                id="input_city" 
                                placeholder=""
                                value={this.state.city}
                                valid={ this.state.validate.cityState === 'has-success' }
                                invalid={ this.state.validate.cityState === 'has-danger' }
                                onChange={ (e) => {
                                  this.setState(validateCity(e));
                                  this.handleCity(e);
                                } }
                              />
                            </Col>
                          </FormGroup>
        
                          <FormGroup row>
                            <Label for="age" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_AGE : TXT.RESERVE.FORM_LABEL_AGE}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_age"
                                valid={ this.state.validate.ageState === 'has-success' }
                                invalid={ this.state.validate.ageState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleAge(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_AGE_SELECT : TXT.RESERVE.FORM_LABEL_AGE_SELECT}</option>
                                <option>&lt; 20</option>
                                <option>21 - 30</option>
                                <option>31 - 40</option>
                                <option>41 - 50</option>
                                <option>51 - 60</option>
                                <option>60 &gt;</option>
                              </Input>
                            </Col>
                          </FormGroup>
        
                          <FormGroup row>
                            <Label for="checkin" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHECKIN_DATE : TXT.RESERVE.FORM_LABEL_CHECKIN_DATE}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="date" 
                                name="checkin" 
                                id="input_checkin" 
                                placeholder=""
                                value={this.state.checkin}
                                valid={ this.state.validate.checkinState === 'has-success' }
                                invalid={ this.state.validate.checkinState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleCheckin(e);
                                } }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="checkout" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHECKOUT_DATE : TXT.RESERVE.FORM_LABEL_CHECKOUT_DATE}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="date" 
                                name="checkout" 
                                id="input_checkout" 
                                placeholder=""
                                value={this.state.checkout}
                                valid={ this.state.validate.checkoutState === 'has-success' }
                                invalid={ this.state.validate.checkoutState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleCheckout(e);
                                } }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="roomtype" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE : TXT.RESERVE.FORM_LABEL_ROOMTYPE}
                            </Label>
                            <Col sm={9}>
                              <Input 
                                type="select" 
                                name="select" 
                                id="input_roomtype" 
                                valid={ this.state.validate.roomtypeState === 'has-success' }
                                invalid={ this.state.validate.roomtypeState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleRoomtype(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP1 : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP1}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP2 : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP2}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP3 : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP3}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP4 : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP4}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP5 : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP5}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP6 : TXT.RESERVE.FORM_LABEL_ROOMTYPE_SELECT_OP6}</option>
                              </Input>
                            </Col>
                          </FormGroup>
        
                          <FormGroup row>
                            <Label for="num_rooms" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NUM_ROOMS : TXT.RESERVE.FORM_LABEL_NUM_ROOMS}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_num_rooms"
                                valid={ this.state.validate.num_roomsState === 'has-success' }
                                invalid={ this.state.validate.num_roomsState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleNum_Rooms(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NUM_ROOMS_SELECT : TXT.RESERVE.FORM_LABEL_NUM_ROOMS_SELECT}</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NUM_ROOMS_SELECT_OP6 : TXT.RESERVE.FORM_LABEL_NUM_ROOMS_SELECT_OP6}</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="num_adults" sm={3}>
                              <span style={{ color: 'red' }}>*</span>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NUM_ADULTS : TXT.RESERVE.FORM_LABEL_NUM_ADULTS}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_num_adults"
                                valid={ this.state.validate.num_adultsState === 'has-success' }
                                invalid={ this.state.validate.num_adultsState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleNum_Adults(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NUM_ADULTS_SELECT : TXT.RESERVE.FORM_LABEL_NUM_ADULTS_SELECT}</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_NUM_ADULTS_SELECT_OP6 : TXT.RESERVE.FORM_LABEL_NUM_ADULTS_SELECT_OP6}</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="children12" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHILDREN12 : TXT.RESERVE.FORM_LABEL_CHILDREN12}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_children12"
                                valid={ this.state.validate.children12State === 'has-success' }
                                invalid={ this.state.validate.children12State === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleChildren12(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHILDREN12_SELECT : TXT.RESERVE.FORM_LABEL_CHILDREN12_SELECT}</option>
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHILDREN12_SELECT_OP6 : TXT.RESERVE.FORM_LABEL_CHILDREN12_SELECT_OP6}</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="children6" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHILDREN6 : TXT.RESERVE.FORM_LABEL_CHILDREN6}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_children6"
                                valid={ this.state.validate.children6State === 'has-success' }
                                invalid={ this.state.validate.children6State === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleChildren6(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHILDREN6_SELECT : TXT.RESERVE.FORM_LABEL_CHILDREN6_SELECT}</option>
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_CHILDREN6_SELECT_OP6 : TXT.RESERVE.FORM_LABEL_CHILDREN6_SELECT_OP6}</option>
                              </Input>
                            </Col>
                          </FormGroup>
        
                          <FormGroup row>
                            <Label for="breakfast" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_BREAKFAST : TXT.RESERVE.FORM_LABEL_BREAKFAST}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_breakfast"
                                valid={ this.state.validate.breakfastState === 'has-success' }
                                invalid={ this.state.validate.breakfastState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleBreakfast(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_BREAKFAST_SELECT : TXT.RESERVE.FORM_LABEL_BREAKFAST_SELECT}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_BREAKFAST_SELECT_OP1 : TXT.RESERVE.FORM_LABEL_BREAKFAST_SELECT_OP1}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_BREAKFAST_SELECT_OP2 : TXT.RESERVE.FORM_LABEL_BREAKFAST_SELECT_OP2}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_BREAKFAST_SELECT_OP3 : TXT.RESERVE.FORM_LABEL_BREAKFAST_SELECT_OP3}</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="interests" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS : TXT.RESERVE.FORM_LABEL_INTERESTS}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_interests"
                                valid={ this.state.validate.interestsState === 'has-success' }
                                invalid={ this.state.validate.interestsState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleInterests(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP1 : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP1}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP2 : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP2}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP3 : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP3}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP4 : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP4}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP5 : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP5}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP6 : TXT.RESERVE.FORM_LABEL_INTERESTS_SELECT_OP6}</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="how_findus" sm={3}>
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_HOW_FINDUS : TXT.RESERVE.FORM_LABEL_HOW_FINDUS}
                            </Label>
                            <Col sm={9}>
                              <Input
                                type="select"
                                name="select"
                                id="input_how_findus"
                                valid={ this.state.validate.how_findusState === 'has-success' }
                                invalid={ this.state.validate.how_findusState === 'has-danger' }
                                onChange={ (e) => {
                                  this.handleHow_Findus(e);
                                } }>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT : TXT.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP1 : TXT.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP1}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP2 : TXT.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP2}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP3 : TXT.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP3}</option>
                                <option>{APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP4 : TXT.RESERVE.FORM_LABEL_HOW_FINDUS_SELECT_OP4}</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Label for="message">
                              {APP_LANG === 'EN' ? TXTEN.RESERVE.FORM_LABEL_MESSAGE : TXT.RESERVE.FORM_LABEL_MESSAGE}</Label>
                            <Input 
                              type="textarea" 
                              name="text" 
                              id="input_message" 
                              valid={ this.state.validate.messageState === 'has-success' }
                              invalid={ this.state.validate.messageState === 'has-danger' }
                              onChange={ (e) => {
                                this.handleMessage(e);
                              } }
                            />
                          </FormGroup>
        
                          { 
                            this.state.formSent ? BUTTON_SEND_DISABLE : BUTTON_SEND

                          }
                              
                        </Form>
                    </Card>
                  </Col>
                </Container>
            </div>
            );
    } else {
        return (
            <div className="section" style={{ marginTop: 100 }}>
            <Container>
              <Col sm="12" md={{ size: 8, offset: 2 }}>
                <Card body className="text-center">
                    <CardTitle>
                        <h1 style={{ color: 'green' }}>
                            {APP_LANG === 'EN' ? TXTEN.RESERVE.SUCCESS_TITLE : TXT.RESERVE.SUCCESS_TITLE}
                        </h1>
                    </CardTitle>
                    <CardText>
                    {APP_LANG === 'EN' ? TXTEN.RESERVE.SUCCESS_MESSAGE : TXT.RESERVE.SUCCESS_MESSAGE}
                    </CardText>
                </Card>
              </Col>
            </Container>
            </div>
        );
    }
    
  }

}

export default FormReservation;
