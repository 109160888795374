
export const validateName=(e) => {
    const nameRegExp = /^(([a-zA-Z]{4,}))$/;
    const { validate } = {validate:{nameState: '',}}
      if (nameRegExp.test(e.target.value)) {
        validate.nameState = 'has-success'
      } else {
        validate.nameState = 'has-danger'
      }
      return ({ validate });
    }

export const validateEmail=(e) => {
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = {validate:{emailState: '',}}
      if (emailRegExp.test(e.target.value)) {
        validate.emailState = 'has-success'
      } else {
        validate.emailState = 'has-danger'
      }
      return ({ validate });
    }

export const validatePhone=(e) => {
    const phoneRegExp = /^((\+?-?([0-9 ]{7,})))$/;  
    const { validate } = {validate:{phoneState: '',}}
        if (phoneRegExp.test(e.target.value)) {
        validate.phoneState = 'has-success'
        } else {
        validate.phoneState = 'has-danger'
        }
        return ({ validate });
    }

export const validateCountry=(e) => {
    const countryRegExp = /^(([a-zA-Z]{3,}))$/;
    const { validate } = {validate:{countryState: '',}}
      if (countryRegExp.test(e.target.value)) {
        validate.countryState = 'has-success'
      } else {
        validate.countryState = 'has-danger'
      }
      return ({ validate });
}

export const validateCity=(e) => {
    const cityRegExp = /^(([a-zA-Z]{3,}))$/;
    const { validate } = {validate:{cityState: '',}}
      if (cityRegExp.test(e.target.value)) {
        validate.cityState = 'has-success'
      } else {
        validate.cityState = 'has-danger'
      }
      return ({ validate });
}

export const validateEmpty=(dataSend) => {
    const { validate } = {validate:{nameState: '', emailState: '',}}
    const successForm = true;
    if (dataSend.name === '' && dataSend.name !== undefined) {
        validate.nameState = 'has-danger';
    }
    else if (dataSend.email === '' || dataSend.email === undefined) {
        validate.emailState = 'has-danger';
    }
    else if (dataSend.country === '' || dataSend.country === undefined) {
        validate.countryState = 'has-danger';
    }
    else if (dataSend.age === '' || dataSend.age === 'Please Select...' || dataSend.age === undefined) {
        validate.ageState = 'has-danger';
    }
    else if (dataSend.checkin === '' || dataSend.checkin === undefined) {
        validate.checkinState = 'has-danger';
    }
    else if (dataSend.checkout === '' || dataSend.checkout === undefined) {
        validate.checkoutState = 'has-danger';
    }
    else if (dataSend.roomtype === '' || dataSend.roomtype === 'Please Select...' || dataSend.roomtype === undefined) {
        validate.roomtypeState = 'has-danger';
    }
    else if (dataSend.num_rooms === '' || dataSend.num_rooms === 'Please Select...' || dataSend.num_rooms === undefined) {
        validate.num_roomsState = 'has-danger';
    }
    else if (dataSend.num_adults === '' || dataSend.num_adults === 'Please Select...' || dataSend.num_adults === undefined) {
        validate.num_adultsState = 'has-danger';
    }
    else {
        console.log('no empty fields:',{ validate } );
        return successForm;
    }
    console.log('Some empty fields:',{ validate } );
    return ({ validate });
}